import React, { useEffect,useState } from 'react'
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BASE_URL from './config.js';
import TopNavbar from './components/TopNavbar.js';
import UserBg from './assets/bgDown.png'

const Admin = () => {
  const navigate = useNavigate();

  const [userdetails , setUserdetails ] = useState({});

  useEffect(()=>{
    const callAbout= async()=>{
        try{
        const res = await fetch(`${BASE_URL}/getauthadmin`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            },
            credentials:"include"
        })
        if(res.status === 200){
          const data = await res.json();
          setUserdetails(data.UserDetails);
        }else if(res.status === 201)
        {
          navigate('/user');
        }
        else{
            const error = new Error(res.error);
            throw error;
        }
    }catch(err)
    {
      console.log(err);
      navigate('/');
    }
    };
    callAbout()
  },[])
  return (
    <div style={{
      height: '100vh',
      width: '100%',
      backgroundImage: `url(${UserBg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      overflow: 'auto',
    }}>
      <TopNavbar userdetails={userdetails}/>
      <div className="container mt-3">
        {/* <div className="d-flex justify-content-between my-3 align-items-center">
            <img src='/images/logo.jpg' alt='Logo' style={{width:'100px'}}/>
            <h3>Kunstocom India Ltd</h3>
        </div> */}
        <Outlet/>
      </div>
    </div>
  )
}

export default Admin