let BASE_URL;

if (process.env.NODE_ENV === 'production') {
    BASE_URL = "http://35.154.140.67:8081";
} else {
    BASE_URL = "http://localhost:8000";
}

// http://localhost:8000
export default BASE_URL;
