export const ItemDetails = {
    Sample_1:'',
    Sample_2:'',
    Sample_3:'',
    Sample_4:'',
    Sample_5:''
}

export const FirstFive = {
    Material_Grade:ItemDetails,
    Master_Batch_Grade:ItemDetails,
    Master_Batch_Percentage:ItemDetails,
    Total_Cycle_Time:ItemDetails,
    Cooling_Time:ItemDetails,
    Appearance:ItemDetails,
    Color:ItemDetails,
    Component_Weight:ItemDetails,
    Packing:ItemDetails
}