import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import BASE_URL from '../../config';

const AddTask = () => {
    const [taskName, setTaskName] = useState('');
    const userId = localStorage.getItem('userid');

    const handleSave = async () => {
        const newTask = {
            taskName,
            assignedTo: userId,
            status: 'Pending Approval'
        };

        try {
            const res = await fetch(`${BASE_URL}/tasks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newTask)
            });

            if (res.ok) {
                setTaskName(''); 
                alert("Task created and sent for approval!");
                window.location.href = '/user/mytasks';
            } else {
                console.error('Failed to save task');
            }
        } catch (error) {
            console.error('Error saving task:', error);
        }
    };

    return (
        <div className="container mt-4">
            <div className='row'>
                <div className='col-md-8 mb-3'>
                    <TextField
                        size='large'
                        className='w-100'
                        label="Task Name"
                        variant="outlined"
                        value={taskName}
                        onChange={(e) => setTaskName(e.target.value)}
                    />
                </div>
                <div className='col-md-4 mb-3'>
                    <Button
                        variant="contained"
                        color="primary"
                        className='w-100'
                        size='large'
                        onClick={handleSave}
                    >
                        Create Task
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddTask;
