export const ItemDetails = {
    T_09:'',
    T_11:'',
    T_01:'',
    T_03:'',
    T_05:'',
    T_07:'',
    Remarks:''
}

export const TimeCheck = {
    Material_Grade:ItemDetails,
    Master_Batch_Grade:ItemDetails,
    Master_Batch_Percentage:ItemDetails,
    Total_Cycle_Time:ItemDetails,
    Cooling_Time:ItemDetails,
    Barrel_Temp:{
        T_1:ItemDetails,
        T_2:ItemDetails,
        T_3:ItemDetails,
        T_4:ItemDetails,
        T_5:ItemDetails,
        T_6:ItemDetails,
        T_7:ItemDetails,
    },
    Visual_Defect:{
        Short_Molding:ItemDetails,
        Shrinkage:ItemDetails,
        Scratches:ItemDetails,
        Stress_mark:ItemDetails,
        Flashes:ItemDetails,
        Black_spot:ItemDetails,
        Flow_marks:ItemDetails,
        Silver_marks:ItemDetails,
        Crack:ItemDetails
    },
    Color:ItemDetails,
    Weight:ItemDetails
}