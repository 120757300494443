import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';

const ViewAccordion = ({ title, content, accordionindex }) => {
  const expand = accordionindex === 0 ? true : false;
  const allsubheadings = ["compliance","minimization","appearance","workerbehavior","sortout","setinorder","shine","rightposition","rightcontainer","right_quantity","status_board","issue_improvement","my_machine",
  "improvement","A_injection","press","materail","materail_depot","product_warehouse_managment"]  
  return (
    <Accordion defaultExpanded={expand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Accordin_Time_Check_${title}`}>
        <h4 className='fw-bolder'>{title.replace(/_/g, ' ')}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div className='d-flex flex-column gap-3'>
        {Object.entries(content).map(([key, value], index) => (
          <div key={key}>
            {key !== "label" && (
              allsubheadings.includes(key) ? (
                <ViewAccordion title={value.label} content={value} accordionindex={index} />
              ) : (
                value.label !== '' ? (
                  <div className='row'>
                    <div className="col-md-3 mb-2">
                      <TextField size='small' className='w-100' label={value.textfieldvalue} variant="outlined" helperText={'Maximum allowable value:' + value.max_marks} />
                    </div>
                    <div className="col-md-3 mb-2">
                      <TextField multiline size='small' className='w-100' label="Observations" variant="outlined" value={value.observations} disabled />
                    </div>
                    <div className="col-md-3 mb-2">
                      {value.beforeimg === "" ? <div>No Image</div> : <img src={'/images/'+value.beforeimg} alt={value.beforeimg} style={{width:'100%'}} />}
                    </div>
                    <div className="col-md-3 mb-2">
                      {value.afterimg === "" ? <div>No Image</div> : <img src={'/images/'+value.afterimg} alt={value.afterimg} style={{width:'100%'}} />}
                    </div>
                  </div>
                ) : null
              )
            )}
          </div>
        ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ViewAccordion;