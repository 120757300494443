import React, { useEffect, useState } from 'react'
import BASE_URL from '../../config';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

const AllTemplates = () => {
    const [alltemplates, setAlltemplates] = useState();

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(`${BASE_URL}/getalltemplates`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const resultdata = await res.json();
                setAlltemplates(resultdata.alltemplates);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    }

    const DeleteTemplate = async (tempID) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this template?');

        if (!isConfirmed) {
        return;
        }
        try {
            const res = await fetch(`${BASE_URL}/deletetemplate/${tempID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!res.ok) {
                throw new Error('Failed to delete template');
            }

            const data = await res.json();
            alert(data.message);
            window.location.reload();

        } catch (error) {
            console.error('Error deleting template:', error);
        }
    };

    return (
        <div>
            <div className='d-flex justify-content-center my-4'>
                <span className='fs-5 fw-bolder rounded-3 text-white px-3 py-2' style={{ backgroundColor: "#644c95" }}>ALL TEMPLATES</span>
            </div>
            <div className='d-flex flex-wrap justify-content-center'>
                {alltemplates && alltemplates.map((row, rowindex) => (

                    <div key={rowindex} className='mx-5 col-6 col-sm-6 col-md-2 col-lg-2 mb-5'>
                        <Card sx={{
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: '2px 2px 20px rgba(171,41,66,255)'
                            },
                            borderColor: '#b32643', borderWidth: 4, borderStyle: 'solid'
                            
                        }} className='d-flex justify-content-center rounded-5'>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <CardContent sx={{ flex: '1 0 auto', textAlign: 'center' }}>
                                    <Typography component="div" variant="button">
                                        {formatDate(row.date)}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}>
                                    <IconButton component={Link} to={'/admin/createtemplate?templateid=' + row._id} aria-label="edit" sx={{ color: "#b32643" }}>
                                        <AppRegistrationIcon fontSize='large' />
                                    </IconButton>

                                    <IconButton aria-label="delete" onClick={() => DeleteTemplate(row._id)} sx={{ color: "#b32643" }}>
                                        <DeleteIcon fontSize='large' />
                                    </IconButton>
                                </Box>
                                <CardContent sx={{
                                    flex: '1 0 auto', textAlign: 'center',
                                    backgroundColor: rowindex % 2 === 0 ? '#1976d2' : "#c46623", color: "white"
                                }}>
                                    <Typography component="div" variant="h6">
                                        {row.template_name}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </div>
                ))
                }
            </div>

        </div>
    )
}

export default AllTemplates
