import React, { useState, useEffect } from 'react';
import BASE_URL from '../../config';
import { List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Divider, MenuItem, FormControl, InputLabel, Select, Typography, Grid, Button } from '@mui/material';

const UserTable = () => {
    const [audits, setAudits] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState({});
    const [changedPeriods, setChangedPeriods] = useState({});

    const fetchData = async () => {
        try {
            const res2 = await fetch(`${BASE_URL}/getallcustomaudit`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const resultdata2 = await res2.json();
            setAudits(resultdata2.allcustomaudits);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    }

    const periods = ["Daily", "Weekly", "Monthly", "Quarterly", "Semi-Annually", "Annually"];

    const handleChange = (rowindex, auditId, e) => {
        const newValue = e.target.value;
        setSelectedPeriods(prevState => ({
            ...prevState,
            [rowindex]: newValue
        }));
        setChangedPeriods(prevState => ({
            ...prevState,
            [auditId]: newValue
        }));
    };

    const handleSave = async () => {
        const updates = Object.keys(changedPeriods).map(auditId => ({
            auditId,
            period: changedPeriods[auditId]
        }));

        try {
            const res = await fetch(`${BASE_URL}/updateauditperiod`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ updates })
            });

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            alert('Update successful');
            fetchData();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-between w-75 mb-3'>
                <Typography variant="h4" component="div" className='fw-bolder'>
                    All Audits
                </Typography>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
            </div>
            <List dense sx={{ bgcolor: 'background.paper' }} className='d-flex flex-column gap-3 w-75'>
                <Grid container spacing={2} className='mb-3'>
                    <Grid item xs={1}>
                        <Typography variant="subtitle1" className='fw-bolder'></Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle1" className='fw-bolder'>Audit Name</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle1" className='fw-bolder'>Template Name</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle1" className='fw-bolder'>Date</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle1" className='fw-bolder'>Submitted</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" className='fw-bolder'>Period</Typography>
                    </Grid>
                </Grid>
                {audits && audits.map((row, rowindex) => {
                    const labelId = `checkbox-list-secondary-label-${rowindex}`;
                    return (
                        <React.Fragment key={rowindex}>
                            <ListItem className='d-flex gap-3'>
                                <ListItemButton className='d-flex gap-2 w-100'>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={1} className='d-flex justify-content-center'>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={`Avatar n°${rowindex + 1}`}
                                                    src={`/static/images/avatar/${rowindex + 1}.jpg`}
                                                />
                                            </ListItemAvatar>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText id={labelId} primary={row.auditname} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText id={labelId} primary={row.templatename} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText id={labelId} primary={formatDate(row.date)} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText id={labelId} primary={` ${row.isSubmit}`} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl sx={{ minWidth: 120 }} size="small" >
                                                <InputLabel id={`timer-select-label-${rowindex}`}>Period</InputLabel>
                                                <Select
                                                    labelId={`timer-select-label-${rowindex}`}
                                                    id={`timer-select-${rowindex}`}
                                                    label="Period"
                                                    value={selectedPeriods[rowindex] || row.period || ''}
                                                    onChange={(e) => handleChange(rowindex, row._id, e)}
                                                >
                                                    {periods.map((period, index) => (
                                                        <MenuItem key={index} value={period}>
                                                            {period}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </React.Fragment>
                    );
                })}
            </List>
        </div>
    );
}

export default UserTable;
