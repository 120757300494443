import React, { useContext, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllFroms } from '../../store/AppContext';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';
import Form from 'react-bootstrap/Form';
import BASE_URL from '../../config';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const EditAccordion = ({ content, title, accordionindex, onTargetDateChange, onResponsibilityChange }) => {
    const { customineFrom, setCustomineFrom, usertype, setResponsibleUsers, responsibleUsers } = useContext(AllFroms);
    const [users, setUsers] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    // const [rowIndex, setRowIndex] = useState(0);

    useEffect(() => {
        const initialTotalScore = calculateScoreSum(content);
        setTotalScore(initialTotalScore);

        async function fetchData() {
            try {
                const res = await fetch(`${BASE_URL}/getallusers`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const resultdata = await res.json();
                setUsers(resultdata.allusers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [customineFrom, accordionindex]);


    const handleInputChange = (headIndex, subheadIndex, lastindex, field, value) => {
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
                        if (subIndex === subheadIndex) {
                            const updatedLastHeadings = subheading.map((final, finalIndex) => {
                                if (finalIndex === lastindex) {
                                    return {
                                        ...final,
                                        [field]: value
                                    };
                                }
                                return final;
                            });
                            return updatedLastHeadings;
                        }
                        return subheading;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });

            const newTotalScore = calculateScoreSum(updatedHeaders[accordionindex].header_subheading);
            setTotalScore(newTotalScore);

            return updatedHeaders;
        });
    };

    const handleResponsibility = (headIndex, subheadIndex, lastindex, field, value, userId) => {
        if (!responsibleUsers.includes(userId)) {
            setResponsibleUsers([...responsibleUsers, userId]);
        }
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
                        if (subIndex === subheadIndex) {
                            const updatedLastHeadings = subheading.map((final, finalIndex) => {
                                if (finalIndex === lastindex) {
                                    return {
                                        ...final,
                                        [field]: value,
                                        ["responsibleUserId"]: userId
                                    };
                                }
                                return final;
                            });
                            return updatedLastHeadings;
                        }
                        return subheading;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    };

    async function setLogFormImages(mainfield, subfield, headindex, field, files) {
        const formData = new FormData();
        formData.append('file', files[0]);
        const response = await fetch(`${BASE_URL}/saveimage`, {
            method: 'POST',
            body: formData
        });
        if (response.ok) {
            var filepath = await response.json();
            handleInputChange(mainfield, subfield, headindex, field, filepath.filePath);
        } else {
            console.error('Error:', response.statusText);
        }
    }

    // const handleDeleteSubheading = async (headIndex, subheadIndex) => {
    //     try {
    //         const response = await fetch(`${BASE_URL}/deletesubheading`, {
    //             method: 'DELETE',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ headIndex, subheadIndex })
    //         });

    //         if (response.ok) {
    //             setCustomineFrom(prevHeaders => {
    //                 const updatedHeaders = prevHeaders.map((header, index) => {
    //                     if (index === headIndex) {
    //                         const updatedSubheadings = header.header_subheading.filter((_, subIndex) => subIndex !== subheadIndex);
    //                         return {
    //                             ...header,
    //                             header_subheading: updatedSubheadings
    //                         };
    //                     }
    //                     return header;
    //                 });

    //                 const newTotalScore = calculateScoreSum(updatedHeaders[accordionindex].header_subheading);
    //                 setTotalScore(newTotalScore);

    //                 return updatedHeaders;
    //             });
    //         } else {
    //             console.error('Error deleting subheading:', response.statusText);
    //         }
    //     } catch (error) {
    //         console.error('Error deleting subheading:', error);
    //     }
    // };

    const calculateScoreSum = (content) => {
        let totalScore = 0;
        if (content) {
            content.forEach((subheading) => {
                subheading.forEach((headname) => {
                    const score = headname.Score;
                    if (score && typeof score === 'number') {
                        totalScore += score;
                    }
                });
            });
        }
        return totalScore;
    };

    const expand = accordionindex === 0 ? true : false;
    const hasData = content.some(subheading => subheading.length > 0);
    return (
        hasData ?
            <Accordion defaultExpanded={expand}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Custom_Accordion_${title}`}>
                    <div className='w-100 d-flex align-items-center justify-content-between'>
                        <h4 className='fw-bolder'>{title.replace(/_/g, " ")}</h4>
                        <span className='px-3'>{totalScore}</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='d-flex flex-column gap-3'>
                        {
                            content && content.length > 0 ? (
                                content.map((subheading, subheadingIndex) => (
                                    subheading.length > 0 ?
                                        <div key={subheadingIndex}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className='fw-bolder fs-6 mt-1'>{subheading[0]?.name}</label>

                                            </div>
                                            {subheading.map((headname, headindex) =>
                                            (<>
                                                <div className='d-flex justify-content-end'>
                                                    {usertype === 'User' && (
                                                        <IconButton
                                                            aria-label="delete" size='small' color='primary'
                                                            onClick={() => onResponsibilityChange(accordionindex, subheadingIndex, headindex)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </div>
                                                <div className='row' key={headindex}>
                                                    {headname.Observation &&
                                                        <div className='col-md-4 mb-3'>
                                                            <label className='mb-1'>&nbsp;</label>
                                                            <TextField
                                                                size='small'
                                                                className='w-100'
                                                                label="Observation"
                                                                variant="outlined"
                                                                value={headname.Observation === true ? '' : headname.Observation}
                                                                disabled={usertype !== 'HOD'}
                                                                onChange={(e) => {
                                                                    const newValue = e.target.value === '' ? true : e.target.value;
                                                                    handleInputChange(accordionindex, subheadingIndex, headindex, "Observation", newValue);
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {headname.Before_Pic &&
                                                        <div className='col-md-4 mb-3'>
                                                            <label className='mb-1'>Before Pic</label><br />
                                                            {headname.Before_Pic === true ? (
                                                                <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                                    <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex, headindex, "Before_Pic", e.target.files)} />
                                                                </Form.Group>
                                                            ) : (
                                                                <>
                                                                    <img src={'/images/' + headname.Before_Pic} alt={headname.Before_Pic} style={{ width: '100%' }} />
                                                                    <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                                        <Form.Control disabled={usertype !== 'HOD'} type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex, headindex, "Before_Pic", e.target.files)} />
                                                                    </Form.Group>
                                                                </>
                                                            )}
                                                        </div>
                                                    }
                                                    {headname.After_Pic &&
                                                        <div className='col-md-4 mb-3'>
                                                            <label className='mb-1'>After Pic</label><br />
                                                            {headname.After_Pic === true ? (
                                                                <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                                    <Form.Control type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex, headindex, "After_Pic", e.target.files)} />
                                                                </Form.Group>
                                                            ) : (
                                                                <>
                                                                    <img src={'/images/' + headname.After_Pic} alt={headname.After_Pic} style={{ width: '100%' }} />
                                                                    <Form.Group controlId="formFile" className='w-100 mt-2'>
                                                                        <Form.Control type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex, headindex, "After_Pic", e.target.files)} />
                                                                    </Form.Group>
                                                                </>
                                                            )}
                                                        </div>
                                                    }
                                                    {headname.Target &&
                                                        <div className='col-md-4 mb-3'>
                                                            <label className='mb-1'>&nbsp;</label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    size='small'
                                                                    className='w-100'
                                                                    label="Target"
                                                                    onChange={(date) => {
                                                                        const newValue = date ? date.format('MM/DD/YYYY') : '';
                                                                        if (usertype === 'User') {

                                                                            onTargetDateChange(newValue, headname._id, accordionindex, subheadingIndex, headindex)
                                                                        }
                                                                        else {
                                                                            handleInputChange(accordionindex, subheadingIndex, headindex, "Target", newValue);
                                                                        }



                                                                    }}
                                                                    value={headname.Target === true ? null : dayjs(headname.Target)}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    }
                                                    {headname.Responsibility &&
                                                        <div className='col-md-4 mb-3'>
                                                            <label className='mb-1'>&nbsp;</label>
                                                            <FormControl fullWidth>
                                                                <InputLabel id={`responsibility-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}>Responsibility</InputLabel>
                                                                <Select
                                                                    labelId={`responsibility-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                                    id={`responsibility-select-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                                    label="Responsibility"
                                                                    disabled={usertype !== 'HOD'}
                                                                    value={headname.Responsibility === true ? "" : headname.Responsibility}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value === '' ? true : e.target.value;
                                                                        const selectedItem = users.find(user => user.name === e.target.value)?._id;
                                                                        handleResponsibility(accordionindex, subheadingIndex, headindex, "Responsibility", newValue, selectedItem);
                                                                    }}
                                                                >
                                                                    {users.map((value, key) => (
                                                                        <MenuItem data-id={value._id} key={key} value={value.name}>
                                                                            {value.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    }
                                                    {headname.Score !== undefined && (
                                                        <div className='col-md-4 mb-3'>
                                                            <label className='mb-1'>&nbsp;</label>
                                                            <FormControl fullWidth>
                                                                <InputLabel id={`score-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}>Score</InputLabel>
                                                                <Select
                                                                    labelId={`score-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                                    id={`score-select-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                                    label="Score"
                                                                    disabled={usertype !== 'HOD'}
                                                                    value={headname.Score === true ? "" : headname.Score}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value === '' ? true : parseInt(e.target.value);
                                                                        handleInputChange(accordionindex, subheadingIndex, headindex, "Score", newValue);
                                                                    }}
                                                                >
                                                                    {[1, 2, 3, 4, 5].map((score) => (
                                                                        <MenuItem key={score} value={score}>{score}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                            )
                                            )}
                                        </div> : null
                                ))
                            ) : null
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
            : null
    );
};

export default EditAccordion;
