import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import BASE_URL from '../../config';
import ViewAccordion from './ViewAccordion';
import { AllFroms } from '../../store/AppContext';

const CustomFormView = () => {

    let { customid } = useParams();
    const { usertype, name, userId } = useContext(AllFroms);

    const [templateData, setTemplateData] = useState({});

    useEffect(() => {
        async function GetCustomTemplateData() {
            const response = await fetch(`${BASE_URL}/getsinglecustomaudit`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ customid, userId, usertype })
            });
            var formData = await response.json();
            if (response.ok) {
                setTemplateData(formData.customaudit);
                console.log(formData.customaudit);
            } else {
                console.error('Error:', response.statusText);
            }
        }

        GetCustomTemplateData();
    }, [])
    return (
        <div className='mb-5'>
            <div className='pb-2 fw-light'>Audit created By : {name} ({usertype})</div>
            {
                templateData.template_detail ? templateData.template_detail.map((key, value) => (
                    <ViewAccordion key={key.header_name} title={key.header_name} content={key.header_subheading} accordionindex={value} />
                    
                )) : null
            }
        </div>
    )
}

export default CustomFormView