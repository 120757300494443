import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import BASE_URL from '../../config';
import moment from 'moment';
import TaskFilter from '../Manager/Filter';
import toast from 'react-hot-toast';

const MyTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [isHovered, setIsHovered] = useState(null);
    const [isButtonHovered, setIsButtonHovered] = useState(null);
    const [formData, setFormData] = useState({
        picture: '',
        location: '',
        comment: '',
        status: 'Completed'
    });
    const [useAutomaticLocation, setUseAutomaticLocation] = useState(false);
    const userId = localStorage.getItem('userid');

    const [filters, setFilters] = useState({
        startDate: moment().startOf('day').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DD'),
        userId: userId,
        status: ''
    });

    useEffect(() => {
        fetchMyTasks();
    }, [filters]);


    const fetchMyTasks = async () => {
        try {
            const res = await fetch(`${BASE_URL}/alltasks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(filters)
            });
            const resultdata = await res.json();
            setTasks(resultdata.allTasks);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    }

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const resetFilters = () => {
        setFilters({
            startDate: moment().startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            userId: userId,
            status: ''
        });
        fetchMyTasks();
    };

    const handleFileChange = async (files) => {
        const imageFormData = new FormData();
        imageFormData.append('file', files[0]);

        try {
            const response = await fetch(`${BASE_URL}/saveimage`, {
                method: 'POST',
                body: imageFormData
            });

            if (response.ok) {
                const filePath = await response.json();
                handleInputChange('picture', filePath.filePath);
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;

                        try {
                            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
                            const data = await response.json();

                            resolve(data.display_name);
                        } catch (error) {
                            reject('Error fetching location name');
                        }
                    },
                    (error) => {
                        reject('Error getting location: ' + error.message);
                    }
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };

    const handleCheckIn = async (task) => {
        if (task.status === "Pending Approval") {
            toast.error("Need's approval before check in");
            return;
        }

        if (task.status === "Rejected") {
            toast.error("Rejected: Cannot check in");
            return;
        }

        setSelectedTask(task);
        if (window.confirm("Do you want to check in the task?")) {
            const checkInTime = new Date().toISOString();
            try {
                const checkInLocationName = await getUserLocation();

                const res = await fetch(`${BASE_URL}/tasks/${task._id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        checkInTime: checkInTime,
                        checkInLocation: checkInLocationName
                    })
                });

                if (res.ok) {
                    toast.success("Task checked in successfully!");
                    fetchMyTasks();
                } else {
                    throw new Error('Failed to check in task');
                }
            } catch (error) {
                console.error('Error checking in task:', error);
                toast.error('Error getting location or checking in task.');
            }
        }
    };

    const handleCheckOut = async () => {
        try {
            const checkOutTime = new Date().toISOString();
            const checkOutLocationName = await getUserLocation();

            const res = await fetch(`${BASE_URL}/attendance`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId,
                    checkOutTime,
                    checkOutLocation: checkOutLocationName
                })
            });
            const result = await res.json();
            if (res.ok) {
                localStorage.removeItem("ischeckedIn");
                toast.success(result.message);
                setTimeout(() => {
                    window.location.href = "/user";
                }, 1000);

            } else {
                toast.error(result.message || 'Something went wrong. Please try again.');
            }
        } catch (error) {
            console.error('Error checking out:', error);
            toast.error('Failed to check out. Please try again later.');
        }

    };

    const handleViewDetails = (task) => {
        setSelectedTask(task);
        setFormData({
            picture: task.picture || '',
            location: task.location || '',
            comment: task.comment || '',
            status: task.status === 'Completed' ? 'Completed' : 'Pending'
        });
        setOpenModal(true);
    };

    const handleLocationChange = async () => {
        if (useAutomaticLocation) {
            try {
                const locationName = await getUserLocation();

                handleInputChange('location', locationName);
            } catch (error) {
                console.error('Error fetching location:', error);
            }
        }
    };

    const handleSubmitAndCheckOut = async () => {
        if (!selectedTask) return;

        setFormData((prevData) => ({
            ...prevData,
            status: 'Completed'
        }));

        try {
            const checkOutLocationName = await getUserLocation();

            const res = await fetch(`${BASE_URL}/tasks/${selectedTask._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...formData,
                    status: 'Completed',
                    checkOutLocation: checkOutLocationName
                })
            });

            if (res.ok) {
                const updatedData = await res.json();
                setTasks((prevTasks) =>
                    prevTasks.map(task => task._id === updatedData.task._id ? updatedData.task : task)
                );
                alert("Task completed successfully");
                setOpenModal(false);
            } else {
                console.error('Failed to update task');
            }
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    const getStatusBgColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'bg-success';
            case 'Rejected':
                return 'bg-danger';
            case 'Pending Approval':
                return 'bg-warning';
            case 'Pending':
                return 'bg-white text-danger';
            default:
                return 'bg-secondary';
        }
    };

    return (
        <div className="container mt-4">
            <div className="d-flex flex-column flex-sm-row gap-sm-4 justify-content-end align-items-end mb-3">
                <TaskFilter
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                />
                <Button variant="success" className="mb-2 mb-sm-0" onClick={handleCheckOut} >
                    Check out
                </Button>
            </div>

            <div className="row">
                {tasks.length > 0 ? (
                    tasks.map((task) => (
                        <div key={task._id} className="col-md-4 my-3">

                            <div
                                className='card h-fit border-0 rounded-4'
                                onClick={() => task.checkInTime ? handleViewDetails(task) : null}
                                onMouseEnter={() => setIsHovered(task._id)}
                                onMouseLeave={() => setIsHovered(null)}
                                style={{
                                    transition: '0.4s all',
                                    boxShadow: isHovered === task._id ? '0px 0px 30px 2px rgba(0, 0, 0, 0.2)' : '0px 5px 7px 1px rgba(0, 0, 0, 0.2)',
                                    cursor: 'pointer',
                                    background: 'linear-gradient(180deg, rgba(110,215,230,1) 0%, rgba(97,251,193,1) 97%)'
                                    // background: 'linear-gradient(180deg, rgba(75,227,125,1) 0%, rgba(97,251,193,1) 100%)'
                                }}
                            >

                                <div className="card-body p-0">
                                    <div className='p-3'>
                                        <h5 className="card-title text-white mb-3 text-center" style={{ fontFamily: "Lucida Console", textShadow: "2px 0px 10px gray" }}>{task.taskName}</h5>
                                        <p className="card-text"><strong>Status:</strong> <span style={{ fontFamily: "Lucida Console" }} className={`badge rounded-4 ${getStatusBgColor(task.status)}`}>{task.status}</span></p>
                                        <p className="card-text"><strong>Comment:</strong> {task.comment || 'N/A'}</p>
                                        <p className="card-text"><strong>Created:</strong> {moment(task.createdAt).format('LLL')}</p>

                                    </div>
                                    <div className='d-flex justify-content-center rounded-bottom-4 p-3 bg-white'>
                                        <Button
                                            variant=""
                                            className='border-4 rounded-3 border'
                                            onClick={() => task.checkInTime ? handleViewDetails(task) : handleCheckIn(task)}
                                            onMouseEnter={() => setIsButtonHovered(task._id)}
                                            onMouseLeave={() => setIsButtonHovered(null)}
                                            style={{
                                                transition: '0.4s all',
                                                boxShadow: isButtonHovered === task._id ? '0px 0px 10px 0px rgba(0, 0, 0, 0.2)' : 'none'
                                            }}
                                        >
                                            {task.checkInTime ? "View Details" : "Check In"}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 12 18">
                                                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                            </svg>
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No beats available</p>
                )}
            </div>

            {selectedTask && (
                <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Beat: {selectedTask.taskName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formPicture" className="d-flex flex-column mb-3">
                                <Form.Label>Uploaded Picture</Form.Label>
                                {selectedTask.picture && (
                                    <img src={'/images/' + selectedTask.picture} alt={selectedTask.picture} className="img-fluid mb-3" />
                                )}
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleFileChange(e.target.files)}
                                    disabled={selectedTask.status === "Completed"}
                                />
                            </Form.Group>
                            <Form.Group controlId="formLocation" className="mb-3">
                                <Form.Label>Location</Form.Label>
                                <Form.Check
                                    type="radio"
                                    label="Enter manually"
                                    checked={!useAutomaticLocation}
                                    onChange={() => setUseAutomaticLocation(false)}
                                    disabled={selectedTask.status === "Completed"}
                                />
                                <Form.Control
                                    type="text"
                                    name="location"
                                    placeholder="Enter your location here"
                                    value={formData.location}
                                    onChange={(e) => handleInputChange('location', e.target.value)}
                                    disabled={useAutomaticLocation}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Get my location"
                                    checked={useAutomaticLocation}
                                    onChange={() => setUseAutomaticLocation(true)}
                                    disabled={selectedTask.status === "Completed"}
                                />
                                {useAutomaticLocation && (
                                    <Button variant="primary" onClick={handleLocationChange} className="mt-2">
                                        Fetch Location
                                    </Button>
                                )}
                            </Form.Group>
                            <Form.Group controlId="formComment" className="mb-3">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="comment"
                                    rows={3}
                                    placeholder="Leave a comment"
                                    value={formData.comment}
                                    disabled={selectedTask.status === "Completed"}
                                    onChange={(e) => handleInputChange('comment', e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setOpenModal(false)}>
                            Close
                        </Button>
                        <Button
                            variant={formData.status === 'Completed' ? 'success' : 'primary'}
                            onClick={handleSubmitAndCheckOut}
                            disabled={selectedTask.status === 'Completed' || selectedTask.status === 'Pending Approval' || selectedTask.status === 'Rejected'}
                        >
                            {formData.status === 'Completed' ? 'Completed' : 'Submit & Check Out'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default MyTasks;
