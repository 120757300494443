import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, Card, FormControlLabel, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import BASE_URL from '../../config';

function CreateTemplate() {

  const location = useLocation();
  const templateid = new URLSearchParams(location.search).get('templateid');

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`${BASE_URL}/viewtemplate/${templateid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        const resultdata = await res.json();
        console.log(resultdata);
        setHeaders(resultdata.alltemplates.header_details);
        setTemplateName(resultdata.alltemplates.template_name);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    if (templateid) {
      fetchData();
    }

  }, [templateid]);


  const [headerValue, setHeaderValue] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [headers, setHeaders] = useState([]);
  const [newSwitchLabels, setNewSwitchLabels] = useState('');

  const handleAddSwitch = (headerindex, subindex) => {
    const key = `${headerindex}-${subindex}`;
    const newSwitchLabel = newSwitchLabels[key];
    if (!newSwitchLabel || newSwitchLabel.trim() === '') return;

    const newHeaders = [...headers];
    const newSubheading = newHeaders[headerindex].header_subheading[subindex];

    newSubheading[0][newSwitchLabel] = true;

    newHeaders[headerindex].header_subheading[subindex] = newSubheading;
    setHeaders(newHeaders);
    setNewSwitchLabels(prevState => ({ ...prevState, [key]: '' }));
  };

  const handleSwitchLabelChange = (headerindex, subindex, value) => {
    const key = `${headerindex}-${subindex}`;
    setNewSwitchLabels(prevState => ({ ...prevState, [key]: value }));
  };

  const handleDeleteSwitch = (headerindex, subindex, switchLabel) => {
    const newHeaders = [...headers];
    delete newHeaders[headerindex].header_subheading[subindex][0][switchLabel];
    setHeaders(newHeaders);
  };
  const handleInputChange = (event) => {
    setHeaderValue(event.target.value);
  };

  const handleTemplateChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleSaveHeader = () => {
    if (headerValue.trim() !== '') {
      const newHeader = {
        header_id: Math.random(),
        header_name: headerValue,
        header_subheading: []
      };
      const updatedHeaders = [...headers, newHeader];
      setHeaders(updatedHeaders);
      setHeaderValue('');
    } else {
      alert('Please enter a valid header value.');
    }
  };

  const handleAddSubheading = (headerIndex) => {
    const updatedHeaders = [...headers];
    const headerToUpdate = updatedHeaders[headerIndex];
    if (headerToUpdate) {
      headerToUpdate.header_subheading.push([{ name: '', Observation: true, Before_Pic: true, After_Pic: true, Target: true, Responsibility: true, Score: true }]);
      setHeaders(updatedHeaders);
    }
  };

  const handleLabelChange = (headIndex, subheadIndex, labelName, labelvalue) => {
    setHeaders(prevHeaders => {
      const updatedHeaders = [...prevHeaders];
      const headerToUpdate = updatedHeaders[headIndex];
      if (headerToUpdate && headerToUpdate.header_subheading[subheadIndex]) {
        const subheadingToUpdate = headerToUpdate.header_subheading[subheadIndex][0];
        if (labelName === "name") {
          subheadingToUpdate[labelName] = labelvalue;
        } else {
          subheadingToUpdate[labelName] = !subheadingToUpdate[labelName];
        }
      }
      return updatedHeaders;
    });
  };

  const SaveTemplate = async (e) => {
    try {
      e.preventDefault();
      const requestData = {
        allheaders: headers,
        tempname: templateName,
        templateid: templateid
      };
      // console.log(requestData);
      const res = await fetch(`${BASE_URL}/createtemplate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });
      const resultdata = await res.json();
      alert(resultdata.message);
      window.location.href = "/admin";
    } catch (error) {
      console.error('Upload failed front', error);
    }
  }

  const handlHeadereDelete = (headerIndex) => {
    const updatedHeaders = [...headers];
    updatedHeaders.splice(headerIndex, 1);
    setHeaders(updatedHeaders);
  }

  const handleDeleteSubheading = (headerIndex, subheadIndex) => {
    const updatedHeaders = [...headers];
    const headerToUpdate = updatedHeaders[headerIndex];
    if (headerToUpdate) {
      headerToUpdate.header_subheading.splice(subheadIndex, 1);
      setHeaders(updatedHeaders);
    }
  }

  return (
    <div>
      <form onSubmit={SaveTemplate} style={{ backgroundColor: '#de823f' }} className='d-flex flex-column gap-3 p-5 rounded-4'>
        <div className='d-flex'>
          <TextField
            variant='outlined'
            size='medium'
            className='w-100'
            label="Template Name"
            value={templateName}
            required
            onChange={handleTemplateChange}
            sx={{

              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                  borderRadius: '0px',
                  borderWidth: '2px'
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },

              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'white',
              }
            }}
          />
        </div>
        <div className='d-flex gap-3 mt-3'>
          <TextField
            variant='outlined'
            size='medium'
            className='w-100'
            label="Header Name"
            value={headerValue}
            onChange={handleInputChange}
            sx={{

              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                  borderRadius: '0px',
                  borderWidth: '2px'
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },

              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'white',
              }
            }}
          />
          <Button variant="contained" className='w-25 bg-white fs-6 fw-bolder rounded-3' sx={{ color: "#9b3054" }} onClick={handleSaveHeader}>Add Header</Button>
        </div>
        <div className='row mt-3'>
          {headers.map((header, headerindex) => (
            <div key={header.header_id} className='col-md-12 mb-3'>
              <Card variant="outlined" className='p-3'>
                <div className='d-flex flex-column gap-2'>
                  <div className='d-flex gap-3'>
                    <TextField
                      variant='outlined'
                      size='medium'
                      className='w-100'
                      label="Check Point"
                      value={header.header_name}
                      disabled
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddSubheading(headerindex)}>
                      <AddIcon></AddIcon>
                    </Button>
                    <Button variant="contained" onClick={() => handlHeadereDelete(headerindex)}>
                      <DeleteIcon></DeleteIcon>
                    </Button>
                  </div>
                  <div className='row mt-3'>
                    {header.header_subheading.map((subheading, subindex) => (
                      <div key={`${subheading} + ${subindex}`} className='col-md-4 mb-3'>
                        <Card variant="outlined" className='p-3'>
                          <div className='d-flex gap-2'>
                              <TextField
                                variant='outlined'
                                size='medium'
                                className='w-100'
                                label="Specification"
                                required
                                value={subheading[0].name}
                                onChange={(e) => handleLabelChange(headerindex,subindex,'name',e.target.value)}
                              />
                              <Button variant="contained" onClick={() => handleDeleteSubheading(headerindex,subindex,)}>
                                <DeleteIcon></DeleteIcon>
                              </Button>
                          </div>
                          <div className='d-flex flex-column gap-3 mt-2' >
                            {Object.keys(subheading[0]).filter(key => key !== 'name' && key !== '_id').map((switchLabel, index) => (
                              <div key={index} className='d-flex justify-content-between'>
                                <FormControlLabel
                                  control={<Switch checked={subheading[0][switchLabel]} onChange={() => handleLabelChange(headerindex, subindex, switchLabel)} />}
                                  label={switchLabel.replace(/_/g, " ")}
                                />
                                <IconButton aria-label="delete" size='large' color='primary' onClick={() => handleDeleteSwitch(headerindex, subindex, switchLabel)}>
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            ))}
                            <div className='d-flex gap-2 mt-2'>
                              <TextField
                                variant='outlined'
                                size='small'
                                className='w-100'
                                label="New Switch Label"
                                value={newSwitchLabels[`${headerindex}-${subindex}`] || ''}
                                onChange={(e) => handleSwitchLabelChange(headerindex, subindex, e.target.value)}
                              />
                              <Button variant="contained" onClick={() => handleAddSwitch(headerindex, subindex)}>
                                <AddIcon />
                              </Button>
                            </div>
                          </div>

                        </Card>
                      </div>
                    ))}
                    {/* {header.header_subheading.map((subheading, index) => (
                      <div key={index} className='col-md-4 mb-3'>
                        <Card variant="outlined" className='p-3'>
                          <h2>{subheading}</h2>
                          <div className='d-flex gap-2'>
                            <TextField
                              variant='outlined'
                              size='medium'
                              className='w-100'
                              label="Specification"
                              value={subheading.name}
                            />
                            <Button
                              variant="contained"
                              onClick={() => handleAddSubheading(header.header_id)}>
                              <AddIcon></AddIcon>
                            </Button>
                          </div>
                          <div className='d-flex flex-column gap-3 mt-2'>
                            <div>
                              <FormControlLabel
                                control={<Switch checked={subheading.Observation} onChange={() => handleLabelChange(header.header_id, 'Observation')} />}
                                label="Observation"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                control={<Switch checked={subheading.Before_Pic} onChange={() => handleLabelChange(header.header_id, 'Before_Pic')} />}
                                label="Before Pic"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                control={<Switch checked={subheading.After_Pic} onChange={() => handleLabelChange(header.header_id, 'After_Pic')} />}
                                label="After Pic"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                control={<Switch checked={subheading.Target} onChange={() => handleLabelChange(header.header_id, 'Target')} />}
                                label="Target"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                control={<Switch checked={subheading.Responsibility} onChange={() => handleLabelChange(header.header_id, 'Responsibility')} />}
                                label="Responsibility"
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))} */}
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
        <div className='d-flex gap-3'>
          {/* <Button variant="contained" style={{width:'150px'}} type='submit' onClick={(e)=> SaveTemplate(e)}>Save</Button> */}
          <Button variant="contained" className='w-25 bg-white fs-6 fw-bolder rounded-3' sx={{ color: "#9b3054" }} type='submit'>Save</Button>
        </div>
      </form>
    </div>
  );
}

export default CreateTemplate;
