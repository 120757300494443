import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BASE_URL from '../config';
import Profile from '../assets/profile.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';


const adminPagesLinks = {
  'Templates': '',
  'Create Template': '/createtemplate',
  'Create User': '/createuser',
  'All Users': '/usertable',
  'Re-Audit': '/auditupdate'
};

const userPagesLinks = {
  'My Beats': '/mytasks',
  'Add New Beat': '/addtasks'
}
const managerPagesLinks = {
  'User Attendance': '/attendance',
}
const TopNavbar = ({ userdetails }) => {
  const [checkInModal, setCheckInModal] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (userdetails.usertype === 'HOD') {
      fetchRequests();
    }
  }, [userdetails.usertype]);

  const fetchRequests = async () => {
    try {
      const response = await fetch(`${BASE_URL}/getapprovalrequests`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await response.json();
      // console.log(result);
      if (response.ok) {
        setNotifications(result.requests);
      } else {
        console.error('Error:', result.message);
      }
    } catch (error) {
      console.error('Fetch requests failed:', error);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    const res = await fetch(`${BASE_URL}/logout`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    });
    const resultdata = await res.json();
    localStorage.removeItem('userid');
    localStorage.removeItem('username');
    localStorage.removeItem('usertype');

    alert(resultdata.message);
    window.location.href = "/";
  }

  const handleNavigation = (page) => {
    const isCheckedIn = localStorage.getItem('ischeckedIn');

    if (page === "/mytasks" && !isCheckedIn) {
      setCheckInModal(true);
      return;
    }

    var url = userdetails.usertype === "User" ? `/user${page}` : userdetails.usertype === 'Manager' ? `/manager${page}` : `/admin${page}`;
    window.location.href = url;
  };

  const handleClose = async () => {
    setCheckInModal(false);
  }

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            try {
              const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
              const data = await response.json();

              resolve(data.display_name);
            } catch (error) {
              reject('Error fetching location name');
            }
          },
          (error) => {
            reject('Error getting location: ' + error.message);
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  const handleMainCheckIn = async () => {
    try {
      const userId = localStorage.getItem('userid');
      const checkInTime = new Date().toISOString();
      const checkInLocationName = await getUserLocation();

      const res = await fetch(`${BASE_URL}/attendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userId,
          checkInTime,
          checkInLocation: checkInLocationName
        })
      });

      const result = await res.json();

      if (res.ok) {
        localStorage.setItem('ischeckedIn', true);
        setCheckInModal(false);
        toast.success(result.message);
        setTimeout(() => {
          window.location.href = "/user/mytasks";
        }, 1000);
      } else {
        toast.error(result.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error checking in:', error);
      toast.error('Failed to check in. Please try again later.');
    }
  };

  const handleOpenNotificationsModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleApprove = async (requestId) => {
    try {
      const response = await fetch(`${BASE_URL}/approveRequest`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ requestId })
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        fetchRequests();
      } else {
        console.error('Error:', result.message);
      }
    } catch (error) {
      console.error('Approve request failed:', error);
    }
    handleCloseModal();
  };

  const handleReject = async (requestId) => {
    try {
      const response = await fetch(`${BASE_URL}/rejectRequest`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ requestId })
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        fetchRequests();
      } else {
        console.error('Error:', result.message);
      }
    } catch (error) {
      console.error('Reject request failed:', error);
    }
    handleCloseModal();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }} className='position-sticky z-3 top-0'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="overline"
            noWrap
            component="a"
            href="/user"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontSize: 25,
              fontWeight: 900,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              AUDIT MANAGEMENT SYSTEM
            </Box>
          </Typography>

          {userdetails.admin ?
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {Object.entries(adminPagesLinks).map(([page, link]) => (
                  <MenuItem key={page} onClick={() => handleNavigation(link)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            : null}

          {userdetails.usertype === "User" ?
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {Object.entries(userPagesLinks).map(([page, link]) => (
                  <MenuItem key={page} onClick={() => handleNavigation(link)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            : null}

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            AUDIT MANAGEMENT SYSTEM
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className='justify-content-end mx-3'>
            {userdetails.admin &&
              Object.entries(adminPagesLinks).map(([page, link]) => (
                <Button
                  key={page}
                  onClick={() => handleNavigation(link)}
                  sx={{ my: 2, color: 'black', display: 'block' }} className='mx-2'>
                  {page}
                </Button>
              ))
            }
            {userdetails.usertype === "User" &&
              Object.entries(userPagesLinks).map(([page, link]) => (
                <Button
                  key={page}
                  onClick={() => handleNavigation(link)}
                  sx={{ my: 2, color: 'black', display: 'block' }} className='mx-2'>
                  {page}
                </Button>
              ))
            }
            {userdetails.usertype === "Manager" &&
              Object.entries(managerPagesLinks).map(([page, link]) => (
                <Button
                  key={page}
                  onClick={() => handleNavigation(link)}
                  sx={{ my: 2, color: 'black', display: 'block' }} className='mx-2'>
                  {page}
                </Button>
              ))
            }
          </Box>

          {userdetails.usertype === 'HOD' && (
            <IconButton color="inherit" onClick={handleOpenNotificationsModal}>
              <NotificationsIcon />
              {notifications.length > 0 && (
                <span className="notification-badge">{notifications.length}</span>
              )}
            </IconButton>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" className='d-flex'>
              <div className='d-flex flex-column'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userdetails.name} src={Profile} sx={{ width: 50, height: 50 }} />
                </IconButton>
                <div className='d-flex justify-content-center'>
                  <span>{userdetails.usertype}</span>
                </div>
              </div>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Pending Requests</DialogTitle>
        <DialogContent>
          {notifications.length > 0 ? (
            <Grid container spacing={2}>
              {notifications.map((request) => (
                <Grid item xs={12} key={request._id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {request.newTargetDate ? `Target change request from ${request.userName}` : `Delete responsibility request from ${request.userName}`}
                      </Typography>
                      <Typography color="textSecondary">
                        {`Audit: ${request.auditId.auditname}`}
                      </Typography>
                      <Typography color="textSecondary">
                        {`Template: ${request.auditId.templatename}`}
                      </Typography>
                      <Typography color="textSecondary">
                        {request.newTargetDate && `New Target Date: ${request.newTargetDate}`}
                      </Typography>
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button onClick={() => handleReject(request._id)} color="secondary" variant="contained" sx={{ mr: 1 }}>
                          Reject
                        </Button>
                        <Button onClick={() => handleApprove(request._id)} color="primary" variant="contained">
                          Approve
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <DialogContentText>No pending requests</DialogContentText>
          )}
        </DialogContent>
      </Dialog>

      {checkInModal && (
        <Modal show={checkInModal} onHide={handleClose} centered>

          <Modal.Body>
            <div className="text-center" style={{ fontFamily: "IBM Plex Sans" }}>
              <h4 className="mb-3 text-primary mt-3">
                🚀 Ready to Get Started?
              </h4>
              <p className="mb-4">
                To view your assigned beats and start managing your work, you need to check in first.
              </p>
              <p className="font-weight-bold mb-4">
                Click the button below to check in and unlock your beats!
              </p>
            </div>
            <div className="d-flex justify-content-center gap-2 border-0">
              <Button size="small" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="success"
                size="small"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleMainCheckIn}
              >
                Check In
              </Button>
            </div>
          </Modal.Body>

        </Modal>
      )}
    </AppBar>
  )
}

export default TopNavbar;


