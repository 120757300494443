import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import BASE_URL from '../../config';

const EditUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setuserDetails] = useState({
    name: '',
    email: '',
    password: '',
    usertype: 'User',
  })

  const location = useLocation();
  // const [isedit , setIsedit] = useState(false);
  const userid = new URLSearchParams(location.search).get('userid');

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`${BASE_URL}/getuserdetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ userid })
        });
        const resultdata = await res.json();
        if (res.status === 200) {
          setuserDetails(prevState => ({
            ...prevState,
            name: resultdata.Userdetails.name,
            email: resultdata.Userdetails.email,
            password: '',
            usertype: resultdata.Userdetails.usertype
          }));
        } else {
          window.location.href = '/admin/usertable';
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [userid]);


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSelect = (event) => {
    setuserDetails(pre => ({
      ...pre,
      usertype: event.target.value
    }));
  }

  const setUserdetailsFunction = (field, value) => {
    setuserDetails(pre => ({
      ...pre,
      [field]: value
    }));
  }

  const saveUserDetails = async (e) => {
    try {
      e.preventDefault();
      const res = await fetch(`${BASE_URL}/edituser/${userid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(userDetails)
      });
      const resultdata = await res.json();
      alert(resultdata.message);
      if (res.status === 200) {
        window.location.href = "/admin/usertable";
      }
    } catch (err) {
      console.error('User Registration Failed:', err);
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-center mb-3'>
        <span className='fs-4 fw-bolder'>Edit User</span>
      </div>
      <form onSubmit={saveUserDetails}>
        <div className='d-flex flex-column gap-4'>
          <TextField label="User Name" variant='outlined' size='medium' className='w-100' autoComplete='off' value={userDetails.name} onChange={(e) => setUserdetailsFunction("name", e.target.value)} required />
          <TextField label="Email" variant='outlined' size='medium' className='w-100' autoComplete='off' value={userDetails.email} onChange={(e) => setUserdetailsFunction("email", e.target.value)} />
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="Password"
            variant="outlined" required
            size="medium"
            className="w-100"
            value={userDetails.password} onChange={(e) => setUserdetailsFunction("password", e.target.value)}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">User Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userDetails.usertype}
              label="User Type"
              onChange={handleSelect}
            >
              <MenuItem value='User'>User</MenuItem>
              <MenuItem value='HOD'>Head of Department</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" style={{ width: '150px' }} type='submit'>Save</Button>
        </div>
      </form>
    </div>
  )
}

export default EditUser

