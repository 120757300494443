import { createContext, useState } from "react";
import { TimeCheck } from "../components/TimeCheck/TimeCheck";
import { FirstFive } from "../components/FirstFive/FirstFive";
import { DailyProcess } from "../components/DailyProcess/DailyProcess";
import { DailyLine } from "../components/DailyLine/Dailyline";
import { F_11 } from "../components/F_11/F_11";

const AllFroms = createContext();

const AppContext = ({ children }) => {
    const [formDetails, setFormDetails] = useState(TimeCheck);
    const [firstfiveformDetails, setFirstFiveFormDetails] = useState(FirstFive);
    const [dailyprocessFrom, setDailyProcessFrom] = useState(DailyProcess);
    const [usertype, setUserType] = useState(localStorage.getItem("usertype"));
    const [userId, setUserId] = useState(localStorage.getItem("userid"));
    const [name, setName] = useState(localStorage.getItem("username"));
    const [responsibleUsers, setResponsibleUsers] = useState([]);
    const [dailyLineFrom, setDailyLineFrom] = useState(DailyLine);
    const [customineFrom, setCustomineFrom] = useState();
    const [indexDetails, setIndexDetails] = useState();
    const [f_11From, setF_11From] = useState(F_11);
    const [responsible, setResponsible] = useState(false);
    return (
        <AllFroms.Provider value=
            {{
                formDetails, setFormDetails,
                firstfiveformDetails, setFirstFiveFormDetails,
                dailyprocessFrom, setDailyProcessFrom,
                dailyLineFrom, setDailyLineFrom,
                customineFrom, setCustomineFrom,
                f_11From, setF_11From,
                usertype, setUserType,
                name, setName,
                userId, setUserId,
                setResponsibleUsers, responsibleUsers,
                setResponsible, responsible,
                setIndexDetails, indexDetails
            }}>
            {children}
        </AllFroms.Provider>
    )
}

export { AppContext, AllFroms }