import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import BASE_URL from '../../config';

const CreateUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setuserDetails] = useState({
    name: '',
    password: '',
    email:'',
    usertype: 'User',
  })


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSelect = (event) => {
    setuserDetails(pre => ({
      ...pre,
      usertype: event.target.value
    }));
  }

  const setUserdetailsFunction = (field, value) => {
    setuserDetails(pre => ({
      ...pre,
      [field]: value
    }));
  }

  const saveUserDetails = async (e) => {
    try {
      e.preventDefault();
      // console.log(userDetails);
      const res = await fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(userDetails)
      });
      const resultdata = await res.json();
      alert(resultdata.message);
      if (res.status === 200) {
        window.location.href = "/admin/usertable";
      }
    } catch (err) {
      console.error('User Registration Failed:', err);
    }
  }

  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='w-50 px-5 py-4 rounded-4' style={{ backgroundColor: '#de823f' }}>
        <div className='d-flex justify-content-center mb-3 ' >
          <span className='fs-2 fw-bolder'>Create User</span>
        </div>
        <form onSubmit={saveUserDetails}>
          <div className='d-flex flex-column gap-4 align-items-center'>
            <TextField sx={{

              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                  borderRadius: '0px',
                  borderWidth: '2px'
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },

              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'white',
              }
            }} label="User Name" variant='outlined' size='medium' className='w-100' autoComplete='off' value={userDetails.name} onChange={(e) => setUserdetailsFunction("name", e.target.value)} required />
            <TextField sx={{

              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                  borderRadius: '0px',
                  borderWidth: '2px'
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },

              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'white',
              }
            }} label="Email" variant='outlined' size='medium' className='w-100' autoComplete='off' value={userDetails.email} onChange={(e) => setUserdetailsFunction("email", e.target.value)} />
            <TextField sx={{

              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white',
                  borderRadius: '0px',
                  borderWidth: '2px'
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },

              '& .MuiInputLabel-root': {
                color: 'white',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'white',
              }
            }}
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined" required
              size="medium"
              className="w-100"
              value={userDetails.password} onChange={(e) => setUserdetailsFunction("password", e.target.value)}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                      edge="end"
                      sx={{ color: "white" }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" sx={{
                color: 'white',
                '&.Mui-focused': {
                  color: 'white',
                },
              }}>User Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userDetails.usertype}
                label="User Type"
                onChange={handleSelect}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                    borderWidth: '2px',
                    borderRadius: '0px'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                }}
              >
                <MenuItem value='User'>User</MenuItem>
                <MenuItem value='HOD'>Head of Department</MenuItem>
                <MenuItem value='Admin'>Admin</MenuItem>
                <MenuItem value='Manager'>Manager</MenuItem>
              </Select>
            </FormControl>
            <Button type='submit' className='w-25 bg-white fs-6 fw-bolder rounded-3' sx={{ color: "#9b3054" }} variant="contained">Save</Button>
          </div>
        </form>
      </div>

    </div>
  )
}

export default CreateUser