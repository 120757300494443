import React, { useState, useEffect } from 'react';
import { Dropdown, Card, ListGroup, Spinner, Alert, Container, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';
import BASE_URL from '../../config';

const AttendanceViewer = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [attendance, setAttendance] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const res = await fetch(`${BASE_URL}/getallusers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const result = await res.json();
                setUsers(result.allusers);
                setLoading(false);
            } catch (error) {
                setError('Error fetching users');
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleUserSelect = async (userId) => {
        setSelectedUser(userId);
        setLoading(true);
        setError(null);
        try {
            const res = await fetch(`${BASE_URL}/attendance/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await res.json();
            if (result.success) {
                setAttendance(result.attendance?.checkInOutDetails || []);
            } else {
                setError('No attendance records found for the selected user.');
            }
        } catch (error) {
            setError('Error fetching attendance data');
        }
        setLoading(false);
    };

    return (
        <Container className="mt-4">
            <h2 className="text-center mb-4">User Attendance Tracker</h2>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : error ? (
                <Alert variant="danger" className="text-center">
                    {error}
                </Alert>
            ) : (
                <Row>
                    <Col md={4} className="d-flex flex-column align-items-center">
                        <Dropdown className="mb-4 w-100">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                className="w-100 text-center"
                            >
                                {selectedUser
                                    ? users.find(user => user._id === selectedUser)?.name
                                    : 'Select a User to View Attendance'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100">
                                {users.map(user => (
                                    <Dropdown.Item
                                        key={user._id}
                                        onClick={() => handleUserSelect(user._id)}
                                    >
                                        {user.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={8}>
                        {selectedUser && (
                            <Card className="shadow">
                                <Card.Header as="h5" className="bg-primary text-white">
                                    Attendance for {users.find(user => user._id === selectedUser)?.name}
                                </Card.Header>
                                <ListGroup variant="flush">
                                    {attendance.length > 0 ? (
                                        attendance.map((entry, index) => (
                                            <ListGroup.Item
                                                key={index}
                                                className={`d-flex flex-column mb-3 p-3 border ${entry.checkOutTime ? 'bg-light' : 'bg-warning'}`}
                                            >
                                                <div className="d-flex flex-column">
                                                    <div className="p-2 border-left border-primary">
                                                        <strong>Check-In:</strong>{' '}
                                                        {entry.checkInTime
                                                            ? format(new Date(entry.checkInTime), 'MMM d, yyyy h:mm a')
                                                            : 'N/A'}
                                                        <div className="small text-muted">
                                                            Location: {entry.checkInLocation || 'N/A'}
                                                        </div>
                                                    </div>
                                                    <div className="p-2 mt-3 border-left border-warning">
                                                        <strong>Check-Out:</strong>{' '}
                                                        {entry.checkOutTime
                                                            ? format(new Date(entry.checkOutTime), 'MMM d, yyyy h:mm a')
                                                            : 'Not yet checked out'}
                                                        <div className="small text-muted">
                                                            Location: {entry.checkOutLocation || 'N/A'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        ))
                                    ) : (
                                        <ListGroup.Item>No attendance records found.</ListGroup.Item>
                                    )}
                                </ListGroup>
                            </Card>
                        )}
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default AttendanceViewer;
