import React, { useContext, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import { TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AccordionSummary from '@mui/material/AccordionSummary';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AllFroms } from '../../store/AppContext';
import Form from 'react-bootstrap/Form';
import BASE_URL from '../../config';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const AccordionItem = ({ content, title, accordionindex }) => {
   
    
    const { customineFrom, setCustomineFrom, usertype, setResponsibleUsers, responsibleUsers } = useContext(AllFroms);
   
    const [users, setUsers] = useState([]);

    useEffect(() => {

        async function fetchData() {
            try {
                const res = await fetch(`${BASE_URL}/getallusers`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const resultdata = await res.json();
                setUsers(resultdata.allusers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [customineFrom, accordionindex]);


    // const handleInputChange = (accordionIndex, subheadingIndex, headIndex, field, value) => {
    //     setCustomineFrom(prevHeaders => {
    //         const updatedHeaders = prevHeaders.map((header, index) => {
    //             if (index === accordionIndex) {
    //                 const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
    //                     if (subIndex === subheadingIndex) {
    //                         const updatedSubheading = { ...subheading };
    //                         updatedSubheading[headIndex][field] = value;
    //                         return updatedSubheading;
    //                     }
    //                     return subheading;
    //                 });
    //                 return {
    //                     ...header,
    //                     header_subheading: updatedSubheadings
    //                 };
    //             }
    //             return header;
    //         });
    //         return updatedHeaders;
    //     });
    //     console.log(customineFrom);
    // };



    async function setLogFormImages(mainfield, subfield, headindex, field, files) {
        const formData = new FormData();
        formData.append('file', files[0]);

        const response = await fetch(`${BASE_URL}/saveimage`, {
            method: 'POST',
            body: formData
        });
        if (response.ok) {
            var filepath = await response.json();
            handleInputChange(mainfield, subfield, headindex, field, filepath.filePath)
        } else {
            console.error('Error:', response.statusText);
        }
    }

    const handleAddSubheading = (headIndex, subheadIndex, newRowData) => {
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((sub, subIndex) => {
                        if (subIndex === subheadIndex) {
                            // Clone the existing subheading array and push the new row into it
                            const newSubheadingArray = [...sub, newRowData];
                            return newSubheadingArray;
                        }
                        return sub;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    }

    const handleInputChange = (headIndex, subheadIndex, lastindex, field, value) => {
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
                        if (subIndex === subheadIndex) {
                            const updatedLastHeadings = subheading.map((final, finalIndex) => {
                                if (finalIndex === lastindex) {
                                    return {
                                        ...final,
                                        [field]: value
                                    };
                                }
                                return final;
                            });
                            return updatedLastHeadings;
                        }
                        return subheading;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    };

    const handleResponsibility = (headIndex, subheadIndex, lastindex, field, value, userId) => {
        if (!responsibleUsers.includes(userId)) {
            setResponsibleUsers([...responsibleUsers, userId]);
        }
        setCustomineFrom(prevHeaders => {
            const updatedHeaders = prevHeaders.map((header, index) => {
                if (index === headIndex) {
                    const updatedSubheadings = header.header_subheading.map((subheading, subIndex) => {
                        if (subIndex === subheadIndex) {
                            const updatedLastHeadings = subheading.map((final, finalIndex) => {
                                if (finalIndex === lastindex) {
                                    return {
                                        ...final,
                                        [field]: value,
                                        ["responsibleUserId"]: userId
                                    };
                                }
                                return final;
                            });
                            return updatedLastHeadings;
                        }
                        return subheading;
                    });
                    return {
                        ...header,
                        header_subheading: updatedSubheadings
                    };
                }
                return header;
            });
            return updatedHeaders;
        });
    };
    const handleDeleteSubheading = (headerIndex, subheadIndex, lastindex) => {
        const updatedHeaders = [...customineFrom];
        const headerToUpdate = updatedHeaders[headerIndex].header_subheading;
        if (headerToUpdate) {
            headerToUpdate[subheadIndex].splice(lastindex, 1);
            setCustomineFrom(updatedHeaders);
        }
    }

    const expand = accordionindex === 0 ? true : false;
    return (
        <Accordion defaultExpanded={expand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Custom_Accordin_${title}`}>
                <div className='w-100 d-flex align-items-center justify-content-between'>
                    <h4 className='fw-bolder'>{title.replace(/_/g, " ")}</h4>
                    <span className='px-3'></span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className='d-flex flex-column gap-3'>
                    {content.header_subheading ? (
                        content.header_subheading.map((subheading, subheadingIndex) => (
                            <div key={subheadingIndex}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className='fw-bolder fs-6 mb-2'>{subheading[0].name}</label>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleAddSubheading(accordionindex, subheadingIndex, subheading[0])}
                                    >
                                        <AddIcon></AddIcon>
                                    </Button>
                                </div>
                                {customineFrom[accordionindex].header_subheading[subheadingIndex].map((headname, headindex) => (
                                    <div className='row' key={headindex}>
                                        {headname.Observation &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>&nbsp;</label>
                                                <TextField
                                                    size='small'
                                                    className='w-100'
                                                    label="Observation"
                                                    variant="outlined"

                                                    value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Observation === true ? '' : customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Observation}

                                                    onChange={(e) => {
                                                        const newValue = e.target.value === '' ? true : e.target.value;
                                                        handleInputChange(accordionindex, subheadingIndex, headindex, "Observation", newValue);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            headname.Before_Pic &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>Before Pic</label>
                                                <Form.Group controlId="formFile" className='w-100'>
                                                    <Form.Control type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex, headindex, "Before_Pic", e.target.files)} />
                                                </Form.Group>
                                            </div>
                                        }
                                        {
                                            headname.After_Pic &&
                                            <   div className='col-md-4 mb-3'>
                                                <label className='mb-1'>After Pic</label>
                                                <Form.Group controlId="formFile" className='w-100'>
                                                    <Form.Control type="file" onChange={(e) => setLogFormImages(accordionindex, subheadingIndex, headindex, "After_Pic", e.target.files)} />
                                                </Form.Group>
                                            </div>
                                        }
                                        {
                                            headname.Target &&
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>&nbsp;</label>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker size='small' className='w-100' label="Target"
                                                        onChange={(date) => {
                                                            const newValue = date ? date.format('MM/DD/YYYY') : '';
                                                            handleInputChange(accordionindex, subheadingIndex, headindex, "Target", newValue);
                                                        }}
                                                        value={customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Target === true ? null : dayjs(customineFrom[accordionindex].header_subheading[subheadingIndex][headindex].Target)} />
                                                </LocalizationProvider>
                                            </div>
                                        }
                                        {headname.Responsibility &&
                                                <div className='col-md-4 mb-3'>
                                                    <label className='mb-1'>&nbsp;</label>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`responsibility-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}>Responsibility</InputLabel>
                                                        <Select
                                                            labelId={`responsibility-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                            id={`responsibility-select-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                            label="Responsibility"
                                                            disabled={usertype !== 'HOD'}
                                                            value={headname.Responsibility === true ? "" : headname.Responsibility}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value === '' ? true : e.target.value;
                                                                const selectedItem = users.find(user => user.name === e.target.value)?._id;
                                                                handleResponsibility(accordionindex, subheadingIndex, headindex, "Responsibility", newValue, selectedItem);
                                                            }}
                                                        >
                                                            {users.map((value, key) => (
                                                                <MenuItem data-id={value._id} key={key} value={value.name}>
                                                                    {value.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            }
                                        {headname.Score !== undefined && (
                                            <div className='col-md-4 mb-3'>
                                                <label className='mb-1'>&nbsp;</label>
                                                <FormControl fullWidth>
                                                    <InputLabel id={`score-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}>Score</InputLabel>
                                                    <Select
                                                        labelId={`score-select-label-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                        id={`score-select-${accordionindex}-${subheadingIndex}-${headindex}`}
                                                        label="Score"
                                                        value={headname.Score === true ? "" : headname.Score}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value === '' ? true : parseInt(e.target.value);
                                                            handleInputChange(accordionindex, subheadingIndex, headindex, "Score", newValue);
                                                        }}
                                                    >
                                                        {[1, 2, 3, 4, 5].map((score) => (
                                                            <MenuItem key={score} value={score}>{score}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )}
                                        {headindex > 0 ?
                                            <div className='col-md-4 mb-3'>
                                                <div className='mb-1'>&nbsp;</div>
                                                <div className='d-flex gap-5'>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleDeleteSubheading(accordionindex, subheadingIndex, headindex)}
                                                    >
                                                        <DeleteIcon></DeleteIcon>
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleAddSubheading(accordionindex, subheadingIndex, subheading[0])}
                                                    >
                                                        <AddIcon></AddIcon>
                                                    </Button>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : null}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionItem;
