import React, { useState, useEffect } from 'react'
import BASE_URL from '../../config';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';

const UserTable = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(`${BASE_URL}/getallusers`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const resultdata = await res.json();
                setUsers(resultdata.allusers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    }

    const DeleteUser = async (userID) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this user?');

        if (!isConfirmed) {
        return;
        }
        try {
            const res = await fetch(`${BASE_URL}/deleteuser/${userID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!res.ok) {
                throw new Error('Failed to delete User');
            }
            const data = await res.json();
            alert(data.message);
            window.location.reload();
        } catch (error) {
            console.error('Error deleting User:', error);
        }
    };
    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center mb-3'>
                <span className='fs-4 fw-bolder'>All Users</span>
            </div>
            <List List dense sx={{ bgcolor: 'background.paper' }} className='d-flex flex-column gap-3 w-75' >
                {users && users.map((row, rowindex) => {
                    console.log(row)
                    const labelId = `checkbox-list-secondary-label-${rowindex}`;
                    return (
                        <>
                            <ListItem
                                key={rowindex}
                                disablePadding
                                className='d-flex gap-3'
                            >
                                <ListItemButton className='d-flex gap-2'>
                                    <ListItemAvatar className='d-flex justify-content-center'>
                                        <Avatar
                                            alt={`Avatar n°${rowindex + 1}`}
                                            src={`/static/images/avatar/${rowindex + 1}.jpg`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText id={labelId} primary={row.name} />
                                    <ListItemText id={labelId} primary={row.email} />
                                    <ListItemText id={labelId} primary={formatDate(row.date)} />
                                    <ListItemText id={labelId} primary={row.usertype} />
                                    <IconButton aria-label="delete" size="medium" component={Link} to={'/admin/editeuser?userid=' + row._id}>
                                        <AppRegistrationIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={() => DeleteUser(row._id)}>
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </ListItemButton>

                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </>


                    );
                })}
            </List>
        </div>
    )
}

export default UserTable

